.fontWeighClass {
  font-weight: 600;
}
.imageSliderParent {
  height: 500px;
  margin: 0;
  display: block;
}
.slide {
  /* position: relative !important; */
  z-index: 200 !important;
}
.subList {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.loaderSubPage {
  height: 100%;
  display: flex;
  width: 50%;
}
.loaderSubPageMobile {
  height: 100%;
  display: flex;
  width: 50%;
  align-items: center;
}
.loaderSubPageMobileImg {
  max-height: 100px;
}


.sliderText {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  position: relative;
  /* top: -3.75rem; */
  /* left: 1rem; */
}


/* .carousel .thumbs {
  width: 100%;
} */

ul.thumbs {
  padding-inline-start: 0px;
}
.customDot {
  background: red;
  border-radius: 50%;
  box-shadow: 1px 1px 2px #000000e6;
  cursor: pointer;
  display: inline-block;
  filter: alpha(opacity = 30);
  height: 8px;
  margin: 0 8px;
  opacity: 0.3;
  transition: opacity 0.25s ease-in;
  width: 8px;
}
@media only screen and (min-width: 320px) {
  .testRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adjust the gap size as needed */
    z-index: 101;
    position: relative;
    /* right: 1rem; */
  }
  .topMargin {
    margin-top: 10px;
  }




}
@media only screen and (min-width: 360px) {
  .designLotus {
    width: 75vw;
    height: auto;
  }
  .designLotusPosition {
    position: relative;
    /* height: 20vh; */
    /* position: absolute; */
    /* top: 51.2vh; */
    /* z-index: 100; */
  }

}
@media only screen and (min-width: 374px) {
  
  .sliderText {
    top: -11.05rem;
    left: 0rem;
    font-size: 10px;
  }

}
@media only screen and (min-width: 390px) {

  .sliderText {
    top: -9.75rem;
  }
}
@media only screen and (min-width: 410px) {
}
@media only screen and (min-width: 425px) {

  .sliderText {
    top: -9.75rem;
    left: 0rem;
    font-size: 10px;
  }
  /* .sliderText {
    top: -13.5rem;
    left: 0rem;
    font-size: 10px;
  } */

}

@media only screen and (min-width: 430px) {

}
@media only screen and (min-width: 540px) {

 
}
@media only screen and (min-width: 768px) {

  
}
@media only screen and (min-width: 820px) {
}
@media only screen and (min-width: 1200px) {


  .designLotusPosition {
    position: absolute;
    top: 50.5vh;
    z-index: 100;
  }

  .designLotus {
    width: 30rem;
    height: auto;
  }

  .testRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adjust the gap size as needed */
    z-index: 101;
    right: 0;
  }


  .topMargin {
    margin-top: 40px;
  }


}
@media only screen and (min-width: 1900px) {
  .designLotusPosition {
    position: absolute;
    top: 59vh;
    z-index: 100;
  }
}
.parent-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: tomato;
  padding: 40px calc((100% - (200px * 3)) / 3);
}


.flex-item {
  height: 100px;
  flex: 0 0 215px;
  margin: 1em 20px;
  /* border: 2px blue solid; */
}
.serv {
  max-height: 50vh;
}

.serv ul {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  list-style: none;
  padding-left: 0;
}

.serv li {
  margin: 100px;
  margin-top: 0px;
  margin-bottom: 30px;
  /* outline: 1px solid; */
}

.parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  /* left:5vw; */
}

.child {
  flex: 1 0 20%;
  /* margin: 22px; */
  height: 100px;
  /* background-color: blue; */
  /* margin-left: 3rem; */
  max-width: 15vw;

  /* margin-top: 4rem; */
}

.TestItem {
  flex-basis: calc(20% - 20px); /* 25% width for each item minus the gap */
  /* background-color: #ccc; Just for visualization */
  padding: 20px; /* Just for visualization */
  padding-top: 5px;
  padding-bottom: 5px;
}
.TestItemFirst {
  flex-basis: calc(20% - 20px); /* 25% width for each item minus the gap */
  /* background-color: #ccc; Just for visualization */
  padding: 20px; /* Just for visualization */
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  position: relative;
  right: 0.9rem;
}

.firstRowHome {
  position: relative;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  padding-left: 9%;
  padding-right: 10%;
}
.secondRowHome {
  display: flex;
  justify-content: space-around;
  padding-left: 0%;
  padding-right: 0%;
  margin-top: 2.25rem;
}
.thirdRowHome {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 9%;
  margin-top: 2.25rem;
}
.fourthRowHome {
  display: flex;
  justify-content: space-between;
  padding-left: 7.75%;
  padding-right: 7.25%;
  margin-top: 2.25rem;
}
.fifthRowHome {
  display: flex;
  justify-content: space-between;
  padding-left: 3.75%;
  padding-right: 3.25%;
  margin-top: 2.25rem;
}
.sixthRowHome {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 8.5%;
  margin-top: 2.25rem;
}
.seventhRowHome {
  display: flex;
  justify-content: space-between;
  padding-left: 4.5%;
  padding-right: 4.75%;
  margin-top: 2.25rem;
}

.firstRowHomeHindi {
  position: relative;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  padding-left: 22%;
  padding-right: 15.5%;
}
.secondRowHomeHindi {
  display: flex;
  justify-content: space-around;
  padding-left: 5%;
  padding-right: -3%;
  margin-top: 2.25rem;
}
.thirdRowHomeHindi {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 9%;
  margin-top: 2.25rem;
}
.fourthRowHomeHindi {
  display: flex;
  justify-content: space-between;
  padding-left: 6.75%;
  padding-right: 5.25%;
  margin-top: 2.25rem;
}
.fifthRowHomeHindi {
  display: flex;
  justify-content: space-between;
  padding-left: 10.75%;
  padding-right: 6.5%;
  margin-top: 2.25rem;
}
.sixthRowHomeHindi {
  display: flex;
  justify-content: space-between;
  padding-left: 14.5%;
  padding-right: 4.25%;
  margin-top: 2.25rem;
}
.seventhRowHomeHindi {
  display: flex;
  justify-content: space-between;
  padding-left: 11.5%;
  padding-right: 7.75%;
  margin-top: 2.25rem;
}

.loaderDiv {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* OLD */




.car-container {
  /* background-color: red; */
  /* padding: 20px;
  background-image: url('../../assets/content/imageWrapperBorder.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10; */
  position: relative;
}
.carousel-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.carousel-root {
  /* opacity: 0.5; */
  padding : 50px;
}
.pren-car-slide {
  padding: 40px;
  padding-top: 20px;
  padding-bottom: 0;
  height: 100%;
  /* background-color: red; */
  display: grid;
  place-items: center;
}
ul.thumbs {
  padding-inline: 20px;
  /* display: flex;
  justify-content: center; */
}
img.pren-slider-img {
  max-height: 350px;
  object-fit: contain;
}
.thumb-img {
  max-height: 40px;
}
.carousel .carousel-slider > .pren-slider-img {
  /* object-fit: cover; */
  display: block;
  border: 10px solid black!important;
}
.carousel .thumbs {
  /* display: flex;
  justify-content: center; */
  width: min-content;
  margin: 0 auto;
}
.prerna-slider-deco-left {
  position: absolute;
  left:  -78px;
  transform: scaleX(-1);
  /* width: auto; */
  max-height: 250px;
  max-width: 133px;
  width: 35vw;
  top: 50%;
  transform: translateY(-50%) scaleX(-1);
}
.prerna-slider-deco-right {
  position: absolute;
  right: -78px;
  width: auto;
  max-width: 133px;
  max-height: 250px;
  width: 35vw;
  top: 50%;
  transform: translateY(-50%) ;
  /* transform: scaleX(-1); */

  /* object-fit: fill; */
}
.car-wrap-container {
  padding: 3.5em;
  /* max-width: 825px; */
  max-width: min(65vw, 825px);
  margin: 0 auto;
  position: relative;
  padding-top: 0;
}
.carousel .slide img {
  vertical-align: middle !important;
}
@media (max-width: 480px) {
  .carousel-root {
    padding: 20px;
  }

  .pren-car-slide {
    padding: 10px;
  }

  ul.thumbs {
    padding-inline: 10px;
  }
  img.pren-slider-img {
    max-height: 130px;
    object-fit: contain;
  }
  .prerna-slider-deco-left {
    max-width: 65px;
    left: -25px;
  }
  .prerna-slider-deco-right {
    max-width: 65px;
    right: -25px;
  }
  .car-wrap-container {
    padding: 2.5em;
    padding-top: 0;
  }
}

* {
  text-rendering: geometricPrecision;
}